import { Trans } from "@lingui/macro";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button, Popover } from "@mui/material";
import { useAuthState } from "@saleor/sdk";
import { bindPopover, bindToggle, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import Image from "next/image";
import React, { useState } from "react";

import { UserDetailFragment } from "@/saleor/api";

import ProfileMenuList, { NavItem } from "./ProfileMenuList";

export interface LoggedInUserButtonProps {
	user?: UserDetailFragment | null;
}

export const LoggedInUserButton: React.VFC<LoggedInUserButtonProps> = ({ user: apiUser }) => {
	const { user } = useAuthState();
	const popupState = usePopupState({ variant: "popover", popupId: "accountMenu" });

	const popoverToggle = bindToggle(popupState);

	const handleMenuItemClick = (item: NavItem, event: React.MouseEvent<any, MouseEvent>) => {
		popoverToggle.onClick(event);
	};

	const [name, setName] = useState<string>("Hi!");

	React.useEffect(() => {
		if (user?.firstName) {
			setName(`Hi, ${user.firstName}`);
		} else {
			setName("Hi!");
		}
	}, [user]);

	return (
		<>
			<Button
				className="text-md"
				style={{ textTransform: "inherit" }}
				color="inherit"
				startIcon={
					apiUser?.avatar?.url ? (
						<Image
							className="rounded-full"
							src={apiUser?.avatar?.url}
							alt="Avatar"
							width={32}
							height={32}
						/>
					) : (
						<AccountCircleIcon
							style={{ width: 32, height: 32 }}
							className="text-gray-400"
						/>
					)
				}
				size="small"
				{...bindTrigger(popupState)}
			>
				<Trans>
					<span className="font-semibold pl-1">{name}</span>
				</Trans>
			</Button>
			<Popover
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			>
				<ProfileMenuList onItemClick={handleMenuItemClick} />
			</Popover>
		</>
	);
};

export default LoggedInUserButton;
