import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 49 48" {...props}>
			<path
				d="M47.2231 6.91644C46.4169 7.8133 45.2638 8.71959 43.7825 9.63533C43.4638 9.83358 43.1356 10.0224 42.7888 10.2206C41.5606 10.9098 40.1825 11.5895 38.6731 12.2315C38.2231 12.4297 37.8106 12.5902 37.4544 12.7318C36.62 13.0623 36.0856 13.2511 36.0575 13.2605C35.9825 13.2888 35.8981 13.2983 35.8231 13.2983C35.6919 13.2983 35.5606 13.2605 35.4481 13.1944C35.2606 13.0811 35.1388 12.8923 35.12 12.6752C35.0075 11.7878 35.0169 10.8815 35.1575 9.97519C35.2138 9.57869 35.5794 9.31435 35.9638 9.37099C36.3575 9.42764 36.62 9.79582 36.5638 10.1923C36.4981 10.6549 36.4606 11.1175 36.47 11.5801C36.6106 11.5234 36.7794 11.4574 36.9481 11.3913C37.295 11.2497 37.6888 11.0892 38.1106 10.9098C39.5731 10.2867 40.9138 9.62589 42.0856 8.96505C42.3856 8.79512 42.7044 8.60631 43.0419 8.39861C45.2169 7.04861 47.8513 4.9528 47.0919 3.02692C46.7263 2.08286 46.07 1.56363 45.1513 1.45978C43.0606 1.23321 40.0981 3.15909 38.2513 5.93462L37.6981 6.87868C37.5013 7.21854 37.0606 7.33183 36.7231 7.13357C36.3856 6.93532 36.2731 6.49161 36.47 6.15175L37.0325 5.18881C37.0419 5.17937 37.0513 5.16993 37.0513 5.15105C39.2075 1.90349 42.6763 -0.258404 45.3013 0.0248135C46.745 0.175863 47.8513 1.05384 48.4138 2.48881C48.9856 3.92378 48.5825 5.40595 47.2231 6.91644Z"
				fill="#DB5C6E"
			/>
			<path
				d="M35.8231 13.3173C35.6919 13.3173 35.5606 13.2796 35.4481 13.2135C35.27 13.1002 35.1481 12.9114 35.12 12.6942C35.0075 11.8068 35.0169 10.9005 35.1575 9.99423C35.2138 9.59773 35.5794 9.33339 35.9731 9.39004C36.3669 9.44668 36.6294 9.81486 36.5731 10.2114C36.5075 10.674 36.47 11.1365 36.4794 11.5991C36.9106 11.4292 37.4825 11.2026 38.12 10.9289C39.5825 10.3058 40.9231 9.64493 42.095 8.98409C44.3356 7.71905 47.9731 5.2645 47.1013 3.04596C46.7263 2.1019 46.0794 1.58267 45.1606 1.47883C43.07 1.25225 40.1075 3.17813 38.2606 5.95366L37.7075 6.89772C37.5106 7.23758 37.07 7.35087 36.7325 7.15262C36.395 6.95437 36.2825 6.51066 36.4794 6.1708L37.0419 5.20786C37.0513 5.19842 37.0606 5.18898 37.0606 5.17009C39.2169 1.92253 42.6856 -0.239361 45.3106 0.0438565C46.7544 0.194906 47.8606 1.07288 48.4231 2.50785C48.9856 3.92394 48.5825 5.41555 47.2231 6.9166C46.2481 8.00227 44.7575 9.11626 42.7888 10.2208C41.5606 10.91 40.1825 11.5897 38.6731 12.2317C37.1638 12.8831 36.1044 13.2512 36.0575 13.2701C35.9825 13.2984 35.9075 13.3173 35.8231 13.3173Z"
				fill="#DB5C6E"
			/>
			<path
				d="M43.0792 25.1263C43.4635 24.6732 43.726 24.1256 43.8479 23.5497C44.2698 21.4822 44.851 16.1389 42.0385 8.4448C41.6823 7.46297 40.9229 6.69829 39.9479 6.33954C32.3073 3.50737 27.001 4.09268 24.9479 4.51751C24.3667 4.64024 23.8323 4.90457 23.3823 5.29164C14.9635 12.5137 8.60729 18.8483 1.52916 27.0805C0.422914 28.3644 0.376039 30.262 1.42604 31.5931C6.00104 37.418 11.176 42.6292 16.9604 47.2362C18.2823 48.2935 20.1667 48.2463 21.4417 47.1323C29.6073 40.0047 35.8979 33.5945 43.0792 25.1263ZM33.8729 14.5623C32.7948 13.4766 32.7948 11.7112 33.8729 10.6256C34.951 9.5399 36.7042 9.5399 37.7823 10.6256C38.8604 11.7112 38.8604 13.4766 37.7823 14.5623C36.6948 15.648 34.951 15.648 33.8729 14.5623Z"
				fill="#FFD064"
			/>
			<path
				d="M24.9479 4.51782C24.3667 4.64055 23.8323 4.90489 23.3823 5.29195C14.9635 12.514 8.60729 18.8486 1.52916 27.0808C0.422914 28.3647 0.376039 30.2623 1.42604 31.5934C1.50104 31.6973 1.58541 31.7917 1.66041 31.8955C1.47291 30.8759 1.81041 29.7053 2.61666 28.7801C9.94791 20.463 16.4448 13.9867 24.9479 6.4909C25.3979 6.08496 25.9323 5.7923 26.4854 5.63181C28.0604 5.17866 31.576 4.46118 36.4792 5.22587C30.7042 3.68705 26.6823 4.15908 24.9479 4.51782Z"
				fill="#FFC250"
			/>
			<path
				d="M12.0951 31.565C11.9732 31.4423 11.8607 31.2818 11.7951 31.1025C11.5045 30.385 11.842 29.5731 12.5545 29.2804L29.617 22.2566C30.3295 21.964 31.1357 22.3038 31.4263 23.0213C31.717 23.7388 31.3795 24.5507 30.667 24.8433L13.6045 31.8671C13.0701 32.0937 12.4795 31.9521 12.0951 31.565Z"
				fill="#DB5C6E"
			/>
			<path
				d="M17.5514 22.1626C15.7796 20.3783 15.9483 17.3195 17.9171 15.337C19.8858 13.3545 22.9233 13.194 24.6952 14.9688C26.4671 16.7531 26.2983 19.8118 24.3296 21.7944C22.3608 23.7769 19.3233 23.9374 17.5514 22.1626ZM22.7358 16.9419C22.0608 16.2622 20.7483 16.4227 19.8764 17.3101C19.0046 18.1975 18.8358 19.5097 19.5108 20.1895C20.1858 20.8692 21.4983 20.7087 22.3702 19.8213C23.2421 18.9433 23.4108 17.6216 22.7358 16.9419Z"
				fill="#DB5C6E"
			/>
			<path
				d="M18.1044 39.2689C16.3325 37.4847 16.5013 34.4259 18.47 32.4434C20.4388 30.4609 23.4763 30.3004 25.2481 32.0752C27.02 33.85 26.8513 36.9182 24.8825 38.9008C22.9231 40.8927 19.8763 41.0532 18.1044 39.2689ZM23.2888 34.0577C22.6138 33.378 21.3013 33.5385 20.4294 34.4259C19.5575 35.3133 19.3888 36.6256 20.0638 37.3053C20.7388 37.985 22.0513 37.8245 22.9231 36.9371C23.8044 36.0497 23.9638 34.7375 23.2888 34.0577Z"
				fill="#DB5C6E"
			/>
			<path
				d="M35.8232 16.092C34.9326 16.092 34.0419 15.7521 33.3669 15.0724C32.0076 13.7035 32.0076 11.485 33.3669 10.1256C34.0232 9.46471 34.8951 9.09653 35.8232 9.09653C36.7513 9.09653 37.6232 9.46471 38.2794 10.1256C39.6388 11.4944 39.6388 13.713 38.2794 15.0724C37.6044 15.7521 36.7138 16.092 35.8232 16.092ZM35.8232 10.5315C35.2794 10.5315 34.7638 10.7486 34.3794 11.1357C33.5826 11.9381 33.5826 13.2504 34.3794 14.0528C35.1763 14.8553 36.4794 14.8553 37.2763 14.0528C38.0732 13.2504 38.0732 11.9381 37.2763 11.1357C36.8826 10.7486 36.3763 10.5315 35.8232 10.5315Z"
				fill="#FFC250"
			/>
			<path
				d="M43.7823 9.63515C43.4636 9.83341 43.1354 10.0222 42.7886 10.2205C41.5604 10.9096 40.1823 11.5894 38.6729 12.2313C38.2229 12.4296 37.8104 12.5901 37.4542 12.7317C37.2011 12.3352 37.0229 11.8726 36.9479 11.3911C37.2948 11.2495 37.6886 11.089 38.1104 10.9096C39.5729 10.2866 40.9136 9.62571 42.0854 8.96487C42.3854 8.79494 42.7042 8.60613 43.0417 8.39844C43.3698 8.75718 43.6229 9.17257 43.7823 9.63515Z"
				fill="#DB5C6E"
			/>
		</SvgIcon>
	);
};

export default Icon;
