import { Trans } from "@lingui/macro";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useToggle } from "react-use";

import GiftBox from "@/icons/GiftBox";
import OnSale from "@/icons/OnSale";
import PriceTag from "@/icons/PriceTag";
import { MainMenuItemFragment, MenuItem } from "@/saleor/api";

import CartButton from "./CartButton";

type NavItem = MainMenuItemFragment | MenuItem | null;

export type AccordionNavItem = {
	item: NavItem;
	expandable: boolean | true;
};

const MobileMenu = ({ items }: { items: AccordionNavItem[] }) => {
	const [expanded, setExpanded] = React.useState<string | false>("");
	const [isOpen, toggleOpen] = useToggle(false);
	const router = useRouter();

	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};

	const renderTopCatItems = () => {
		return (
			<Box>
				<Box py={1.5}>
					<Link href={`/collection/best-sellers`} passHref>
						<a className="text-white" onClick={toggleOpen}>
							<PriceTag />
							<span className="ml-3">
								<Trans>Best Sellers</Trans>
							</span>
						</a>
					</Link>
				</Box>
				<Box py={1.5}>
					<Link href={`/collection/promotion`} passHref>
						<a className="text-white" onClick={toggleOpen}>
							<OnSale />
							<span className="ml-3">
								<Trans>Promotion</Trans>
							</span>
						</a>
					</Link>
				</Box>
				<Box py={1.5}>
					<Link href={`/products`} passHref>
						<a className="text-white" onClick={toggleOpen}>
							<GiftBox />
							<span className="ml-3">
								<Trans>All Products</Trans>
							</span>
						</a>
					</Link>
				</Box>
			</Box>
		);
	};

	const getUrl = (item: MainMenuItemFragment | MenuItem) => {
		if (item.url) {
			return item.url;
		} else if (item.collection) {
			return `/collection/${item.collection.slug}/`;
		} else if (item.category) {
			return `/category/${item.category.slug}/`;
		}
	};

	const redirectToMenu = (item: MainMenuItemFragment | MenuItem): void => {
		const url = getUrl(item);
		url && router.push(url);
		toggleOpen(false);
	};

	return (
		<div className="relative">
			<div
				onClick={toggleOpen}
				className="bg-gray-900 py-4 px-8 flex flex-row text-white font-semibold items-center"
				style={{ position: "relative" }}
			>
				<MenuIcon />
				<div className="ml-4 text-sm">
					<Trans>BROWSE PRODUCT CATEGORIES</Trans>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={toggleOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div
					style={{
						maxHeight: "100%",
						width: "calc(100% - 48px)",
						overflow: "hidden",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							bgcolor: "common.black",
							flex: 1,
							overflow: "hidden",
						}}
					>
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
							px={4}
							py={1}
							sx={{ bgcolor: "common.white" }}
						>
							<Link href="/">
								<a onClick={toggleOpen}>
									<div className={clsx("block h-12 relative w-40")}>
										<Image
											src={"/gmr-logo.svg"}
											alt="GM Rewards logo"
											layout="fill"
										/>
									</div>
								</a>
							</Link>
							<CartButton />
						</Box>
						<Box px={2} py={1} flex={1} overflow="scroll">
							{items.map((element, index) => {
								const item = element.item;
								const expandable = element.expandable;
								if (!item) {
									return null;
								}

								return !expandable ? (
									<Box
										display="flex"
										flexDirection="row"
										alignItems="center"
										className="p-4 cursor-pointer"
										onClick={item ? () => redirectToMenu(item) : undefined}
									>
										<div
											className={clsx(
												"ml-4 text-white uppercase text-sm font-semibold"
											)}
										>
											{item.name}
										</div>
									</Box>
								) : (
									<Accordion
										key={item.id}
										square={false}
										disableGutters
										sx={{ bgcolor: "common.black" }}
										expanded={expanded === item.id}
										onChange={handleChange(item.id)}
									>
										<AccordionSummary
											expandIcon={
												<ExpandMoreIcon style={{ color: "white" }} />
											}
											aria-controls={`panel${item.id}-content`}
											id={`panel${item.id}-header`}
										>
											<Box
												display="flex"
												flexDirection="row"
												alignItems="center"
											>
												<div
													className={clsx(
														"ml-4 text-white uppercase text-sm font-semibold"
													)}
												>
													{item.name}
												</div>
											</Box>
										</AccordionSummary>
										<AccordionDetails classes={{ root: "pt-0" }}>
											<Box pl={6} mt={-2}>
												<Image
													src="/icons/colorStripe.svg"
													alt=""
													width={64}
													height={3}
												/>
												<Box pt={1}>
													{/* {index === 0 && renderTopCatItems()} */}
													{item.collection?.categories?.edges?.map(
														(subCat) => {
															return (
																<Box key={subCat.node.id} py={1.5}>
																	<Link
																		href={`/category/${subCat.node.slug}`}
																		passHref
																	>
																		<a
																			className="text-white text-md"
																			onClick={toggleOpen}
																		>
																			{subCat.node.name}
																		</a>
																	</Link>
																</Box>
															);
														}
													)}
												</Box>
											</Box>
										</AccordionDetails>
									</Accordion>
								);
							})}
						</Box>
					</Box>
					<Box
						sx={{
							position: "absolute",
							top: "0px",
							right: "0px",
							width: 48,
							height: 48,
							bgcolor: "common.white",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						onClick={toggleOpen}
					>
						<CloseIcon />
					</Box>
				</div>
			</Modal>
		</div>
	);
};

export default MobileMenu;
