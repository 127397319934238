import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_3426_223728)">
					<path
						d="M21.366 24.0018H2.63477C2.14258 24.0018 1.74414 23.6032 1.74414 23.1107V8.77734H22.252V23.1107C22.2566 23.6032 21.8582 24.0018 21.366 24.0018Z"
						fill="#FFC73B"
					/>
					<path d="M1.74414 8.77734H22.252V11.7603H1.74414V8.77734Z" fill="#EFB025" />
					<path
						d="M24 5.66604V9.36195C24 9.75593 23.6812 10.0749 23.2875 10.0749H0.7125C0.31875 10.0749 0 9.75593 0 9.36195V5.66604C0 5.27206 0.31875 4.95312 0.7125 4.95312H23.2828C23.6813 4.95312 24 5.27206 24 5.66604Z"
						fill="#FFC73B"
					/>
					<path
						d="M11.1848 6.65166C11.0301 6.83458 10.8754 7.0175 10.7254 7.20511C10.1676 7.89926 9.66133 8.62156 9.20196 9.37669C9.06133 9.60651 8.92539 9.84102 8.78945 10.0802H3.92383C4.20977 9.43297 4.51914 8.79979 4.85664 8.18068C5.4707 7.05502 6.16914 5.97627 6.94727 4.95379C7.13477 4.7099 7.32695 4.46601 7.51914 4.2315C7.63633 4.0861 7.75352 3.94539 7.87539 3.80469C8.3207 4.18929 8.76133 4.5692 9.20664 4.95379C9.27695 5.01477 9.34258 5.07105 9.41289 5.13202C9.93789 5.58229 10.4629 6.03724 10.9879 6.4875C11.0582 6.54378 11.1191 6.59538 11.1848 6.65166Z"
						fill="#EFB025"
					/>
					<path
						d="M18.4445 10.0763H13.5789C13.4477 9.8418 13.3117 9.60729 13.1664 9.37278C12.707 8.61765 12.1961 7.89536 11.643 7.2012C11.493 7.01359 11.3383 6.83067 11.1836 6.64775C11.2492 6.59147 11.3102 6.53988 11.3758 6.4836C11.9008 6.03333 12.4258 5.57838 12.9508 5.12812C13.0211 5.06714 13.0867 5.01086 13.157 4.94989C13.6023 4.56529 14.0477 4.18538 14.4883 3.80078C14.6102 3.94149 14.7273 4.0822 14.8445 4.22759C15.0414 4.46679 15.2336 4.706 15.4164 4.94989C16.1992 5.97236 16.8977 7.05111 17.5117 8.17677C17.8492 8.80057 18.1633 9.43375 18.4445 10.0763Z"
						fill="#EFB025"
					/>
					<path
						d="M11.9992 6.65166C11.8445 6.83458 11.6898 7.0175 11.5398 7.20511C10.982 7.89926 10.4758 8.62156 10.0164 9.37669C8.55391 11.764 7.5836 14.4328 7.17578 17.2281C6.56172 16.412 5.99453 15.5256 5.48359 14.5828C4.58359 15.1691 3.70703 15.8398 2.86328 16.595C3.29922 13.6307 4.25547 10.7838 5.67578 8.18068C6.43516 6.78768 7.32578 5.46503 8.34297 4.2315C8.46016 4.0861 8.57735 3.94539 8.69922 3.80469C9.21016 4.24557 9.72578 4.69114 10.2367 5.13202C10.7617 5.58229 11.2867 6.03724 11.8117 6.4875C11.8727 6.54378 11.9336 6.59538 11.9992 6.65166Z"
						fill="#FF4440"
					/>
					<path
						d="M21.1398 16.595C20.2961 15.8398 19.4148 15.1691 18.5195 14.5828C18.0086 15.5256 17.4414 16.412 16.8273 17.2281C16.4195 14.4328 15.4492 11.764 13.9867 9.37669C13.5273 8.62156 13.0164 7.89926 12.4633 7.20511C12.3133 7.0175 12.1586 6.83458 12.0039 6.65166C12.0695 6.59538 12.1305 6.53909 12.1961 6.4875C12.7211 6.03724 13.2461 5.58229 13.7711 5.13202C14.282 4.69114 14.7977 4.24557 15.3086 3.80469C15.4305 3.94539 15.5477 4.0861 15.6648 4.2315C16.682 5.46503 17.5727 6.78768 18.332 8.18068C19.7477 10.7838 20.7039 13.6307 21.1398 16.595Z"
						fill="#FF4440"
					/>
					<path d="M10.0859 6.65234H13.9063V10.0762H10.0859V6.65234Z" fill="#FF4440" />
					<path
						d="M18.3281 8.17965L13.9828 9.37566C13.5234 8.62053 13.0125 7.89823 12.4594 7.20408L12.1922 6.48647L12 5.97055L13.7672 5.13099L15.6609 4.23047C16.6781 5.464 17.5687 6.78665 18.3281 8.17965Z"
						fill="#EA2F2F"
					/>
					<path
						d="M12 5.97055L11.8078 6.48647L11.5406 7.20408C10.9828 7.89823 10.4766 8.62053 10.0172 9.37566L5.67188 8.17965C6.43125 6.78665 7.32187 5.464 8.33906 4.23047L10.2375 5.13099L12 5.97055Z"
						fill="#EA2F2F"
					/>
					<path
						d="M18.9328 0.164165L12 3.4614L13.3312 7.04474L20.7328 5.00449C21.6984 4.73714 22.2328 3.7006 21.8812 2.75787L21.2719 1.11628C20.9203 0.173546 19.8422 -0.262646 18.9328 0.164165Z"
						fill="#FF4440"
					/>
					<path
						d="M5.06669 0.164165L11.9995 3.4614L10.6683 7.04474L3.26669 5.00449C2.30106 4.73714 1.76669 3.7006 2.11825 2.75787L2.72763 1.11628C3.07919 0.173546 4.15731 -0.262646 5.06669 0.164165Z"
						fill="#FF4440"
					/>
					<path
						d="M13.6544 4.90221C13.5278 4.90221 13.4059 4.84124 13.3309 4.72398C13.2184 4.54575 13.27 4.31124 13.4481 4.19398C13.4997 4.16115 14.7513 3.36381 16.1809 2.78691C18.2481 1.95205 19.6263 1.99426 20.2825 2.91824C20.4044 3.09178 20.3622 3.33098 20.1934 3.45293C20.0247 3.57487 19.7809 3.53266 19.6591 3.36381C18.7731 2.11152 15.0794 4.05797 13.8559 4.84124C13.795 4.88345 13.7247 4.90221 13.6544 4.90221Z"
						fill="#EA2F2F"
					/>
					<path
						d="M10.3076 4.90014C10.2373 4.90014 10.167 4.88138 10.1013 4.83917C8.87791 4.0559 5.18885 2.10945 4.29822 3.36175C4.17635 3.53529 3.93728 3.57281 3.76385 3.45086C3.59041 3.32892 3.55291 3.08971 3.67478 2.91617C4.33103 1.9922 5.70916 1.9453 7.77635 2.78485C9.21072 3.36644 10.4576 4.15909 10.5092 4.19192C10.6873 4.30448 10.7388 4.54369 10.6263 4.72191C10.556 4.83917 10.4342 4.90014 10.3076 4.90014Z"
						fill="#EA2F2F"
					/>
					<path
						d="M13.0871 2.22656H10.9121C10.3168 2.22656 9.83398 2.70966 9.83398 3.30532V6.46653C9.83398 7.06219 10.3168 7.54529 10.9121 7.54529H13.0871C13.6824 7.54529 14.1652 7.06219 14.1652 6.46653V3.30532C14.1652 2.70966 13.6824 2.22656 13.0871 2.22656Z"
						fill="#EA2F2F"
					/>
					<path d="M10.0879 10.0742H13.9082V23.9948H10.0879V10.0742Z" fill="#FF4440" />
					<path d="M10.0879 10.0742H13.9082V11.758H10.0879V10.0742Z" fill="#EA2F2F" />
				</g>
				<defs>
					<clipPath id="clip0_3426_223728">
						<rect width="24" height="23.9999" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>
	);
};

export default Icon;
