import { t, Trans } from "@lingui/macro";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

type LOCALES = "en" | "th" | "zh" | "pseudo";

function LanguageSwitcher() {
	const router = useRouter();
	const [locale, setLocale] = useState<LOCALES>(router.locale!.split("-")[0] as LOCALES);
	const popupState = usePopupState({ variant: "popover", popupId: "language-switcher" });

	const languages: { [key: string]: string } = {
		en: t`English`,
		th: t`Thai`,
		zh: t`Chinese`,
	};

	// enable 'pseudo' locale only for development environment
	if (process.env.NODE_ENV !== "production") {
		languages["pseudo"] = t`Pseudo`;
	}

	useEffect(() => {
		router.push(router.pathname, router.pathname, { locale });
	}, [locale, router.pathname]);

	const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, locale: LOCALES) => {
		popupState.close();
		setLocale(locale);
	};

	return (
		<>
			<Button variant="text" {...bindTrigger(popupState)}>
				<div className="flex flex-row text-gray-600">
					<Image src="/icons/language.svg" alt="cc" width={20} height={20} />
					<div className="uppercase ml-2 lg:hidden">{locale}</div>
					<div className="ml-2 normal-case hidden lg:block">
						<Trans>Select Language</Trans>
					</div>
				</div>
			</Button>
			<Menu {...bindMenu(popupState)}>
				{Object.keys(languages).map((item) => {
					return (
						<MenuItem
							value={item}
							key={item}
							selected={locale === item}
							onClick={(event) => handleMenuItemClick(event, item as LOCALES)}
						>
							{languages[item as unknown as LOCALES]}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
}

export default LanguageSwitcher;
