import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const useMultipleCheckoutHook = () => {
	const router = useRouter();
	const [isMutlipleCheckout, setIsMutlipleCheckout] = useState<boolean>(false);

	useEffect(() => {
		if (!router.isReady) return;
		if (router.pathname.includes("/multiple/")) {
			setIsMutlipleCheckout(true);
		} else {
			setIsMutlipleCheckout(false);
		}
	}, [router.isReady, router.pathname, router.asPath, setIsMutlipleCheckout]);

	return isMutlipleCheckout;
};

export default useMultipleCheckoutHook;
