import { t, Trans } from "@lingui/macro";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useState } from "react";

import { useCategoryPathsQuery } from "@/saleor/api";

type SearchBarProps = {
	className?: string;
};

const SearchBar: React.VFC<SearchBarProps> = ({ className }) => {
	const router = useRouter();
	const [searchValue, setSearchValue] = useState("");
	const [category, setCategory] = useState<string>();

	const { data } = useCategoryPathsQuery();

	const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const onSearchClick = () => {
		router.push({
			pathname: "/search",
			query: {
				q: searchValue,
				category,
			},
		});
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			onSearchClick();
		}
	};

	const handleMenuChange = (event: SelectChangeEvent) => {
		setCategory(event.target.value as string);
	};

	return (
		<div className={clsx("relative flex flex-row overflow-hidden", className)}>
			<div className="flex flex-row border border-gray-400 h-12">
				{/* NOSONAR <Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={category}
					displayEmpty
					onChange={handleMenuChange}
					className="bg-gray-200 text-gray-900 border-none rounded-none"
					style={{ minWidth: 120, height: 48, borderWidth: 0 }}
					renderValue={(selected) => {
						if (!selected || !data?.categories) {
							return <Trans>All Categories</Trans>;
						}
						return data?.categories?.edges.find(({ node }) => node.id === selected)
							?.node?.name;
					}}
					sx={{
						"& .MuiOutlinedInput-notchedOutline": {
							borderTopWidth: 0,
							borderLeftWidth: 0,
							borderBottomWidth: 0,
						},
					}}
				>
					{data?.categories?.edges.map(({ node }) => {
						return (
							<MenuItem key={node.id} value={node.id}>
								{node.name}
							</MenuItem>
						);
					})}
				</Select> */}
				<input
					value={searchValue}
					onChange={onSearchChange}
					onKeyDown={onKeyDown}
					type="text"
					className="h-full w-40 lg:w-96 px-4 border-0 z-0 focus:outline-none"
					placeholder="Searching for..."
				/>
			</div>
			<button className="bg-brand-blue px-4" onClick={onSearchClick}>
				<SearchIcon className={"flex-shrink-0 h-6 w-6 text-white"} />
			</button>
		</div>
	);
};

export default SearchBar;
