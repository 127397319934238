import { Trans } from "@lingui/macro";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Divider, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuthState } from "@saleor/sdk";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import qs from "qs";
import React, { useState } from "react";

import { useCurrentUserDetailsQuery } from "@/saleor/api";

import LanguageSwitcher from "../LanguageSwitcher";
import CartButton from "./CartButton";
import CategoryMenu from "./CategoryMenu";
import LoggedInUserButton from "./LoggedInUserButton";
import SearchBar from "./SearchBar";

export const Navbar = () => {
	const router = useRouter();
	const { authenticated } = useAuthState();
	const { data: userData } = useCurrentUserDetailsQuery({
		variables: {},
		skip: !authenticated || typeof window === "undefined",
	});

	const theme = useTheme();
	const isTabletUp = useMediaQuery(theme.breakpoints.up("sm"));

	const onSearchClick = () => {
		router.push({
			pathname: "/search",
			query: {},
		});
	};

	const renderTopBar = () => {
		return (
			<div className="bg-gray-100 py-2">
				<div className="max-w-7xl mx-auto px-8 flex flex-row justify-between items-center">
					<div className="text-sm">
						<Trans>
							Visit Malaysia <span className="font-semibold">GMR</span>
						</Trans>
					</div>
					<div className="flex flex-row text-sm items-center">
						<div className="flex-row items-center hidden md:flex">
							<Link href="/about">
								<a className="text-gray-700 hover:text-gray-800 px-2">
									<Trans>About GM Rewards</Trans>
								</a>
							</Link>
							<Link href="/account/orders">
								<a className="text-white hover:text-white bg-brand-blue hover:bg-blue-500 ml-2 px-4 py-1 rounded-full font-semibold">
									<Trans>Track an Order</Trans>
								</a>
							</Link>
							<Link href="/register-seller">
								<a className="text-white hover:text-white bg-brand-green hover:bg-green-600 ml-2 px-4 py-1 rounded-full font-semibold">
									<Trans>Be a Seller</Trans>
								</a>
							</Link>
						</div>
						<button
							className="md:hidden pr-5 py-1 border-r border-gray-400"
							onClick={onSearchClick}
						>
							<SearchIcon className={"flex-shrink-0 h-6 w-6 text-gray-900"} />
						</button>
						<div className="flex flex-row ml-2">
							<LanguageSwitcher />
						</div>
					</div>
				</div>
			</div>
		);
	};

	const [loginUrl, setLoginUrl] = useState<string>("/account/login");

	React.useEffect(() => {
		if (router.asPath !== "/") {
			setLoginUrl(
				`/account/login${qs.stringify({ next: router.asPath }, { addQueryPrefix: true })}`
			);
		} else {
			setLoginUrl("/account/login");
		}
	}, [router.asPath]);

	const renderUserInfo = () => {
		if (!authenticated) {
			return (
				<Button
					variant="text"
					href={loginUrl}
					startIcon={
						<AccountCircleIcon
							className="flex-shrink-0 text-gray-400 group-hover:text-gray-500"
							aria-hidden="true"
							style={{ width: 32, height: 32 }}
						/>
					}
					size="small"
				>
					<span className="text-sm font-medium text-gray-700 group-hover:text-gray-800 capitalize">
						<Trans>Log in</Trans>
					</span>
				</Button>
			);
		}

		return <LoggedInUserButton user={userData?.me} />;
	};

	return (
		<div className="bg-white shadow-sm sticky top-0 z-50">
			{/* {renderTopBar()} */}
			<div className="max-w-7xl mx-auto px-8 py-2">
				<div className="flex justify-between h-16">
					<div className="flex justify-between items-center">
						<Link href="/">
							<div
								className={clsx("mt-px group block h-14 relative cursor-pointer", {
									"w-48": isTabletUp,
									"w-32": !isTabletUp,
								})}
							>
								{/* <Image src={isTabletUp ? "/gmr-logo.svg" : "/gmr-logo.svg"} alt="GM Rewards logo" layout="fill" /> */}
								<Image src={"/gmr-logo.svg"} alt="GM Rewards logo" layout="fill" />
							</div>
						</Link>
					</div>
					<Stack direction="row" alignItems="center" spacing={3}>
						<SearchBar className="hidden md:flex" />
						<Stack
							direction="row"
							alignItems="center"
							spacing={2}
							divider={<Divider className="my-2" orientation="vertical" flexItem />}
						>
							<CartButton />
							{renderUserInfo()}
						</Stack>
					</Stack>
				</div>
			</div>
			<CategoryMenu />
		</div>
	);
};
