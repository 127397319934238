import { t } from "@lingui/macro";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import Blocks from "editorjs-blocks-react-renderer";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { UrlObject } from "url";

import {
	MainMenuItemFragment,
	MenuItem,
	useAllCategoriesQuery,
	useMainMenuQuery,
} from "@/saleor/api";

import useChannelContext from "../shared/contexts/ChannelContext";
import useMultipleCheckoutHook from "../utils/useMultipleCheckoutHook";
import MobileMenu, { AccordionNavItem } from "./MobileMenu";

type NavItem = MainMenuItemFragment | MenuItem | null;

type NavItemProps = {
	item?: NavItem;
	showPopover?: boolean;
	disabled?: boolean;
};

const NavItemComponent = ({ item, showPopover = true, disabled = false }: NavItemProps) => {
	const catCount = item?.collection?.categories?.edges?.length || 0;
	const router = useRouter();

	if (!item) {
		return null;
	}

	const renderCategoriesGrid = () => {
		const cols = Math.ceil(catCount / 6);
		const xsVal = Math.ceil(12 / cols);
		return (
			<Grid
				container
				rowSpacing={2}
				columnSpacing={2}
				maxWidth={220 * cols}
				minWidth={180 * cols}
			>
				{collection?.categories?.edges?.map((subCat) => {
					return (
						<Grid item xs={xsVal} key={subCat.node.id}>
							<Link href={`/category/${subCat.node.slug}`} passHref>
								<a className="text-gray-900">{subCat.node.name}</a>
							</Link>
						</Grid>
					);
				})}
			</Grid>
		);
	};

	const renderPopover = () => {
		if (catCount === 0) {
			return null;
		}
		return (
			<div
				className={clsx(
					"absolute origin-top-left top-10 -left-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 px-8 py-6 hidden",
					!disabled && "group-hover:block group-hover:z-50"
				)}
			>
				<div className="py-1">
					<div className="font-semibold text-sm mb-1">
						{collection?.description && (
							<Blocks data={JSON.parse(collection.description)} />
						)}
					</div>
					<Image src="/icons/colorStripe.svg" alt="" width={64} height={3} />
					<div className="flex flex-row mt-4 items-start">{renderCategoriesGrid()}</div>
				</div>
			</div>
		);
	};

	const redirectToMenu = (url: string | UrlObject | null | undefined): void => {
		url && router.push(url);
	};

	const getUrl = (item: MainMenuItemFragment | MenuItem) => {
		if (item.url) {
			return item.url;
		} else if (item.collection) {
			return `/collection/${item.collection.slug}/`;
		} else if (item.category) {
			return `/category/${item.category.slug}/`;
		}
	};

	const collection = item.collection;
	const url = getUrl(item);
	return (
		<div className="group px-4 relative py-3 duration-700">
			<button
				className="flex flex-row items-center px-0"
				onClick={url ? () => redirectToMenu(url) : undefined}
				disabled={disabled}
			>
				{/* <GMMenuIcon item={item} /> */}
				<div
					className={clsx(
						"ml-2 uppercase text-xs font-semibold",
						disabled ? "text-gray-400" : "text-white"
					)}
				>
					{item.name}
				</div>
			</button>

			{showPopover && renderPopover()}
		</div>
	);
};

const CategoryMenu: React.VFC = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	const { channel } = useChannelContext();
	const isMultipleCheckout = useMultipleCheckoutHook();
	const { data } = useMainMenuQuery({
		variables: {
			channel: channel,
		},
		fetchPolicy: "cache-and-network",
	});

	const { data: categoriesData } = useAllCategoriesQuery({
		variables: {
			channel: channel,
		},
		fetchPolicy: "cache-and-network",
	});

	if (!data) {
		return null;
	}

	const allCategories = {
		id: "category",
		name: t`All Categories`,
		url: "/products",
		collection: {
			id: "allCategory",
			slug: "category",
			categories: categoriesData?.categories,
			description: `{"time": 1642390607977, "blocks": [{"data": {"text": "${t`Showing all Product Categories`}"}, "type": "paragraph"}], "version": "2.20.0"}`,
		},
	};

	const firstItem: NavItem = {
		id: "promotion",
		name: t`Promotions`,
		url: "/collection/promotion",
		collection: {
			id: "promotion",
			slug: "promotion",
		},
	};

	const lastItem: NavItem = {
		id: "bento-sets",
		name: t`Bento Sets`,
		url: "/multiple/bento-sets",
		collection: {
			id: "bento-sets",
			slug: "bento-sets",
		},
	};

	if (matches) {
		const items: AccordionNavItem[] = [
			{ item: allCategories, expandable: true },
			{ item: firstItem, expandable: false },
		];
		if (data?.menu?.items) {
			data.menu.items.forEach((item) => {
				items.push({ item: item, expandable: false });
			});
		}
		items.push({ item: lastItem, expandable: false });
		return <MobileMenu items={items} />;
	}

	return (
		<div className="bg-gray-900 z-90">
			<div className="max-w-7xl mx-auto px-8 flex flex-row items-center">
				<NavItemComponent item={allCategories} disabled={isMultipleCheckout} />
				<NavItemComponent
					item={firstItem}
					showPopover={false}
					disabled={isMultipleCheckout}
				/>
				{data?.menu?.items?.map((item) => (
					<NavItemComponent
						key={item?.id}
						item={item}
						showPopover={false}
						disabled={isMultipleCheckout}
					/>
				))}
				<NavItemComponent item={lastItem} showPopover={false} />
			</div>
		</div>
	);
};

export default CategoryMenu;
