import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import { useAuthState } from "@saleor/sdk";
import { useRouter } from "next/router";
import qs from "qs";
import React, { useState } from "react";

import { useUserDraftCheckoutsQuery } from "@/saleor/api";

import useChannelContext from "../shared/contexts/ChannelContext";
import useMultipleCheckoutHook from "../utils/useMultipleCheckoutHook";

// eslint-disable-next-line @typescript-eslint/ban-types
const CartButton: React.VFC<{}> = () => {
	const { authenticated } = useAuthState();
	const { channel } = useChannelContext();
	const router = useRouter();
	const [pathName, setPathName] = useState<string | null>(null);
	const isMultipleCheckout = useMultipleCheckoutHook();

	const { data: checkouts, refetch } = useUserDraftCheckoutsQuery({
		variables: { first: 100, channel, multipleCheckout: isMultipleCheckout },
		skip: !authenticated || typeof window === "undefined",
		fetchPolicy: "network-only",
	});

	const [cartUrl, setCartUrl] = useState<string>("/account/login");

	React.useEffect(() => {
		if (!router.isReady) return;
		setPathName(router.pathname);
	}, [router.isReady]);

	React.useEffect(() => {
		if (!authenticated) {
			setCartUrl(
				`/account/login${qs.stringify({ next: router.asPath }, { addQueryPrefix: true })}`
			);
		} else {
			if (router.pathname && router.pathname?.includes("/multiple/")) {
				setCartUrl("/multiple/checkout");
			} else {
				setCartUrl("/cart");
			}
			refetch();
		}
	}, [authenticated, router.asPath, router.pathname, isMultipleCheckout]);

	const count = (checkouts && checkouts.userDraftCheckouts?.totalCount) || 0;

	return (
		<>
			<Badge badgeContent={count} color="error">
				<IconButton href={cartUrl}>
					<ShoppingCartIcon color="primary" style={{ width: 22, height: 22 }} />
				</IconButton>
			</Badge>
		</>
	);
};

export default CartButton;
