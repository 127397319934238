import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<g clipPath="url(#clip0_2554_207711)">
				<path
					d="M17.221 14.232L16.4663 5.1664C16.4382 4.80078 16.1335 4.51953 15.7679 4.51953H2.20695C1.84132 4.51953 1.53664 4.80078 1.50851 5.1664L0.00382411 23.2414C-0.0102384 23.4382 0.0553866 23.6304 0.186637 23.7757C0.317887 23.921 0.505387 24.0007 0.702262 24.0007H12.7023C15.196 24.0007 17.221 21.9757 17.221 19.482V14.232Z"
					fill="#FF405C"
				/>
				<path
					d="M15.7645 4.51953H8.98633V24.0007H12.7035C15.1973 24.0007 17.2223 21.9757 17.2223 19.482V14.232L16.4676 5.1664C16.4348 4.80078 16.1301 4.51953 15.7645 4.51953Z"
					fill="#D01273"
				/>
				<path
					d="M11.9988 8.93904C11.6098 8.93904 11.2957 8.62497 11.2957 8.23591V3.71718C11.2957 2.44218 10.2598 1.40625 8.98476 1.40625C7.70976 1.40625 6.67383 2.44218 6.67383 3.71718V8.23591C6.67383 8.62497 6.35977 8.93904 5.9707 8.93904C5.58164 8.93904 5.26758 8.62497 5.26758 8.23591V3.71718C5.27227 1.66875 6.93633 0 8.98476 0C11.0332 0 12.702 1.66875 12.702 3.71718V8.23591C12.702 8.62497 12.3879 8.93904 11.9988 8.93904Z"
					fill="#FFEC29"
				/>
				<path
					d="M11.9988 16.5703C10.7801 16.5703 9.79102 17.5594 9.79102 18.7781C9.79102 19.9969 10.7801 20.9859 11.9988 20.9859C13.2176 20.9859 14.2066 19.9969 14.2066 18.7781C14.2066 17.5594 13.2176 16.5703 11.9988 16.5703ZM11.9988 19.5797C11.5582 19.5797 11.1973 19.2187 11.1973 18.7781C11.1973 18.3375 11.5582 17.9766 11.9988 17.9766C12.4395 17.9766 12.8004 18.3375 12.8004 18.7781C12.8004 19.2187 12.4395 19.5797 11.9988 19.5797Z"
					fill="#E6E6E6"
				/>
				<path
					d="M5.97539 10.543C4.75664 10.543 3.76758 11.532 3.76758 12.7508C3.76758 13.9695 4.75664 14.9586 5.97539 14.9586C7.19414 14.9586 8.1832 13.9695 8.1832 12.7508C8.1832 11.532 7.19414 10.543 5.97539 10.543ZM5.97539 13.557C5.53477 13.557 5.17383 13.1961 5.17383 12.7555C5.17383 12.3148 5.53477 11.9539 5.97539 11.9539C6.41601 11.9539 6.77695 12.3148 6.77695 12.7555C6.77695 13.1961 6.41601 13.557 5.97539 13.557Z"
					fill="#F5F5F5"
				/>
				<path
					d="M5.53513 20.0824C5.23045 19.8386 5.18357 19.398 5.42732 19.0933L11.4508 11.5605C11.6945 11.2558 12.1351 11.209 12.4398 11.4527C12.7445 11.6965 12.7914 12.1371 12.5476 12.4418L6.5242 19.9699C6.29451 20.2605 5.85388 20.3355 5.53513 20.0824Z"
					fill="#F5F5F5"
				/>
				<path
					d="M8.98633 16.8895L12.5488 12.4364C12.7926 12.1317 12.741 11.6911 12.441 11.4473C12.1363 11.2036 11.6957 11.2551 11.452 11.5551L8.98633 14.6348V16.8895Z"
					fill="#E6E6E6"
				/>
				<path
					d="M12.0004 8.93904C11.6113 8.93904 11.2973 8.62497 11.2973 8.23591V3.71718C11.2973 2.44218 10.2613 1.40625 8.98633 1.40625V0C11.0348 0 12.7035 1.66875 12.7035 3.71718V8.23591C12.7035 8.62497 12.3895 8.93904 12.0004 8.93904Z"
					fill="#FFCB00"
				/>
				<path
					d="M23.2958 23.9984H12.7537C12.5474 23.9984 12.3505 23.9093 12.2193 23.75C12.0833 23.5906 12.0271 23.3843 12.0599 23.1781L13.5646 14.1406C13.6208 13.8031 13.9162 13.5547 14.2583 13.5547H21.7912C22.1333 13.5547 22.4287 13.8031 22.4849 14.1406L23.9896 23.1781C24.0224 23.3797 23.9662 23.5906 23.8302 23.75C23.699 23.9093 23.5021 23.9984 23.2958 23.9984Z"
					fill="#3C3A42"
				/>
				<path
					d="M22.4852 14.1406C22.4289 13.8031 22.1336 13.5547 21.7914 13.5547H18.0273V23.9984H23.2961C23.5023 23.9984 23.6992 23.9093 23.8305 23.75C23.9664 23.5906 24.0227 23.3843 23.9898 23.1781L22.4852 14.1406Z"
					fill="#23232D"
				/>
				<path
					d="M19.5328 16.4679C19.1438 16.4679 18.8297 16.1539 18.8297 15.7648V12.7508C18.8297 12.3008 18.4641 11.9492 18.0281 11.9492C17.5781 11.9492 17.2266 12.3148 17.2266 12.7508V15.7648C17.2266 16.1539 16.9125 16.4679 16.5234 16.4679C16.1344 16.4679 15.8203 16.1539 15.8203 15.7648V12.7508C15.8203 11.5414 16.8 10.543 18.0281 10.543C19.2328 10.543 20.2359 11.518 20.2359 12.7508V15.7648C20.2359 16.1539 19.9219 16.4679 19.5328 16.4679Z"
					fill="#FFEC29"
				/>
				<path
					d="M19.532 16.4679C19.143 16.4679 18.8289 16.1539 18.8289 15.7648V12.7508C18.8289 12.3008 18.4633 11.9492 18.0273 11.9492V10.543C19.232 10.543 20.2352 11.518 20.2352 12.7508V15.7648C20.2352 16.1539 19.9211 16.4679 19.532 16.4679Z"
					fill="#FFCB00"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2554_207711">
					<rect width="24" height="23.9999" fill="white" />
				</clipPath>
			</defs>
		</SvgIcon>
	);
};

export default Icon;
